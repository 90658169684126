import StartPageRenderer from '@jetshop/ui/StartPageRenderer'
import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-apollo'
import { styled } from 'linaria/react'
import MaxWidth from '../Layout/MaxWidth'
import LoadingPage from '../LoadingPage'
import StartPageCampaign from './Content/StartPageCampaign'
import StartPageCategories from './Content/StartPageCategories'
import StartPageHero from './Content/StartPageHero'
import StartPageProductGrid from './Content/StartPageProductGrid'
import startPageQuery from './StartPageQuery.gql'
import { StartPageCategoryItem } from './Content/StartPageCategories'
import RenderCustomElements from '../CustomElements/RenderCustomElements'
import { theme } from '../Theme'
import ChannelContext from '@jetshop/core/components/ChannelContext'


const startPageComponents = {
   HERO: StartPageHero,
   CATEGORIES: StartPageCategories,
   CAMPAIGN: StartPageCampaign,
   PRODUCTGRID: StartPageProductGrid,
   CATEGORYITEM: StartPageCategoryItem,
}

const StartPageWrapper = styled('div')`
   width: 100%;
   display: flex;
   flex-direction: column;
`

const TrustPilotContainer = styled('div')`
   width: 100%;
   display: flex;
   flex-direction: column;
   ${theme.below.sm} {
      .trustpilot-widget {
         height: 320px !important;
      }
      .trustpilot-widget > iframe {
         height: 320px !important;
      }
   }
`

const getReviewConfig = selectedChannel => {
   switch (selectedChannel?.id) {
      case 1:
         return {
            langLink: 'https://se.trustpilot.com/review/www.verktygsboden.se',
            reviewLang: 'sv',
            businessUnitId: '4b1f0c55000064000504d9a6',
            dataLocale: selectedChannel?.language?.culture,
         }
      case 2:
         return {
            langLink: 'https://dk.trustpilot.com/review/verktygsboden.dk',
            reviewLang: 'da',
            businessUnitId: '635a2d41d9edb4e839ed7547',
            dataLocale: selectedChannel?.language?.culture,
         }
      case 3:
         return {
            langLink: 'https://se.trustpilot.com/review/www.verktygsboden.se',
            reviewLang: 'no,nb,nn',
            // Swedish businessUnitId is used for Norwegian reviews
            businessUnitId: '4b1f0c55000064000504d9a6',
            // Norwegian businessId
            // businessUnitId: '6389e5947f633f7abc1fc429',
            dataLocale: selectedChannel?.language?.culture,
         }
      default:
         return {
            langLink: 'https://se.trustpilot.com/review/www.verktygsboden.se',
            reviewLang: 'sv',
            businessUnitId: '4b1f0c55000064000504d9a6',
            dataLocale: selectedChannel?.language?.culture,
         }
   }
}

export const MainSectionWrapper = styled(MaxWidth)`
   width: 100%;
   align-self: center;
   padding-left: 1.5rem;
   padding-right: 1.5rem;

   ${theme.below.sm} {
      padding-left: 0px;
      padding-right: 0px;
   }
`
const StartPage = ({ startPageId }) => {
   const { selectedChannel } = useContext(ChannelContext)
   const result = useQuery(startPageQuery, {
      variables: { startPageId: null },
   })
   const { loading } = result

   const reviewConfig = getReviewConfig(selectedChannel)

   useEffect(() => {
      // MANUALLY TRIGGER TRUSTPILOT WIDGET WHEN NECESSARY
      if (
         !loading &&
         result.data &&
         document.getElementById('trustpilotlink')
      ) {
         if (
            typeof window.Trustpilot !== 'undefined' &&
            window.Trustpilot?.Modules?.WidgetManagement?.findAndApplyWidgets
         ) {
            window.Trustpilot.Modules.WidgetManagement.findAndApplyWidgets()
         }
      }
   })

   if (loading || !result?.data) return <LoadingPage />

   const items = result?.data?.startPage?.data?.items

   return (
      <StartPageWrapper>
         <StartPageRenderer
            result={result}
            startPageComponents={startPageComponents}
            LoadingPage={LoadingPage}
         />
         
         <RenderCustomElements items={items} />
         <TrustPilotContainer className="px-128 py-32">
            <div
               className="trustpilot-widget"
               data-locale={reviewConfig?.dataLocale}
               data-template-id="53aa8912dec7e10d38f59f36"
               data-businessunit-id={reviewConfig?.businessUnitId}
               data-style-height="220"
               data-style-width="100%"
               data-theme="light"
               data-stars="4,5"
               data-review-languages={reviewConfig?.reviewLang}
            >
               <a
                  id="trustpilotlink"
                  href={reviewConfig?.langLink}
                  target="_blank"
                  rel="noreferrer"
               >
                  Trustpilot
               </a>
            </div>
         </TrustPilotContainer>
      </StartPageWrapper>
   )
}

export default StartPage
