import { useFetchProductsInOrder } from '../DigifiSearch/Hooks/useDigifiProductsInOrder'

function prepareArticleNumbersToFetch(products) {
   if (!Array.isArray(products)) return []

   const extractedArticleNumbersArr = products?.reduce(
      (accumulatedArticleNumbers, item) => {
         const artNr = item?.productNumber
         if (artNr !== null && artNr !== undefined) {
            accumulatedArticleNumbers.push(artNr)
         }
         return accumulatedArticleNumbers
      },
      []
   )
   return extractedArticleNumbersArr
}

export const HelloRetailCarouselFetcher = ({
   render,
   products, 
   loading: propsLoading,
   ...restProps
}) => {

   const articleNumbersToFetch = prepareArticleNumbersToFetch(products)
   const { data: enhancedProducts, error, loading } = useFetchProductsInOrder(articleNumbersToFetch)

   // Prepare the results including any additional props and loading states
   const modifiedResults = {
      ...restProps,
      products: enhancedProducts,
      loading: propsLoading || loading,
   }

   // Render the component using the provided render prop and the modified results
   return render(modifiedResults)
}
