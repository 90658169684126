import React from 'react'
import {styled} from 'linaria/react'
import {LoadingLine} from '@jetshop/ui/Loading/LoadingLine'
import { theme } from '../Theme'

import RenderCustomElements from '../CustomElements/RenderCustomElements'
import { Breadcrumbs } from '../Core/BreadCrumbs/BreadCrumbs';

const Content = styled('div')`
  padding-bottom: 1rem;
  background: ${theme.colors.white};

  a {
    color: revert;
  }

  .breadcrumbs-wrapper {
    background-color: #E4E4E4 !important;
    & {
      ${theme.below.md} {
         padding-top: 2.5rem !important;
      }
   }

  }
`
function ContentPage(props) {
  const {page} = props
  // const { head } = page
  const items = page?.data?.items
  
  return (
    <>
      {page ? (
        <Content>
          <div className='breadcrumbs-wrapper px-128 py-16'>
            <Breadcrumbs
              parents={props?.result?.data?.route?.parents}
              breadcrumbText={page?.breadcrumbText}
              hideLast
            />
          </div>
          <RenderCustomElements items={items}/>
        </Content>
      ) : (
        <LoadingLine
          heightPx={14}
          widthRem={30}
          randomizeWidthBy={10}
          count={10}
          style={{
            marginBottom: '12px',
          }}
        />
      )}
    </>
  )
}

export default ContentPage
