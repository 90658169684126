import { useEffect, useRef, useState } from "react";
import { useDigifiSearchCore } from "./Core/useDigifiSearchCore";

/**
 * Hook for fetching products in order
 * @param {Array} articleNumbers - Array of article numbers
 * @returns {Object} data - Products in order
 * @returns {string} errorMessage - Error message
 * @returns {boolean} loading - Whether the products are loading
 */
export const useFetchProductsInOrder = (articleNumbers) => {
   const prevArticleNumbersRef = useRef([]);
   const [data, setData] = useState(null);
   const { getProductsByItemNos, loading, errorMessage } = useDigifiSearchCore();

   useEffect(() => {
      if (JSON.stringify(prevArticleNumbersRef.current) === JSON.stringify(articleNumbers)) {
         return;
      }

      prevArticleNumbersRef.current = articleNumbers;

      if (!articleNumbers?.length) return;

      const fetchProducts = async () => {
         const products = await getProductsByItemNos({ itemNos: articleNumbers, pageSize: 48, disableSorting: true });

         if (!products || !Array.isArray(products)) {
            setData(null); // API error case
            return;
         }

         const productMap = products.reduce((acc, product) => {
            acc[product.itemNo] = product;
            return acc;
         }, {});

         const orderedProducts = articleNumbers.map(itemNo => productMap[itemNo]).filter(Boolean);

         setData(orderedProducts);
      };

      fetchProducts();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [articleNumbers]);

   return { data, errorMessage, loading };
};